<script>
import { AlertTimeout } from '@/RegistrationStage.ts'
import { AuthServiceConstants } from '@/msal/AuthServiceInterface'
import { endpoints } from '@/environments/Endpoints.ts'
import { environmentVariables } from '@/environments/EnvironmentVariables.ts'
import { EnvX } from '@/environments/EnvX.ts'
import { RemoteAccess } from '@/RemoteAccess'

export default {
  data() {
    return {
      formisdirty: false,
      displayAnimatedProgressToast: false,
      displayErrorToast: false,
      displayRedeemDialog: false,
      savedFailureErrorMessage: '',
      rules: {
        redeem: [(v) => String(v).length > 0 || 'An invitation code is required', (v) => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(v) || 'Invitation code must be in the exact format from your email']
      },
      redeemInvitationCode: ''
    }
  },
  props: {
    value: Boolean
  },
  mounted() {
    // Invoke this Vue using $router.push({name:params:})
    // pass a StatusRoutes object as parameter
    setTimeout(this.getRedeemInvitationCodeFromLocalStorage, this.nullTimeoutProp)
  },

  methods: {
    getRedeemInvitationCodeFromLocalStorage() {
      try {
        this.redeemInvitationCode = localStorage.getItem('com.proinsight.redeemid')
        if (this.redeemInvitationCode && this.redeemInvitationCode !== '') {
          this.redeemInvitationCodeAction(false)
        } else {
          this.displayRedeemDialog = true
        }
      } catch (exception) {
        EnvX.error('💥', exception)
        // if no code found, have the user enter manually
        this.displayRedeemDialog = true
      }
    },

    formDirty() {
      this.formisdirty = true
    },

    redeemInvitationCodeAction(userInput) {
      var profilebody = {}
      if (this.redeemInvitationCode && this.redeemInvitationCode !== '') {
        profilebody.redeemid = this.redeemInvitationCode
        const countycode = localStorage.getItem('com.proinsight.county')
        if (countycode && countycode !== '') {
          profilebody.countycode = countycode
        }
        const professioncode = localStorage.getItem('com.proinsight.profession')
        if (professioncode && professioncode !== '') {
          profilebody.professioncode = professioncode
        }
        const state = localStorage.getItem('com.proinsight.state')
        if (state && state !== '') {
          profilebody.statecode = state
        }
        const zip = localStorage.getItem('com.proinsight.zip')
        if (zip && zip !== '') {
          profilebody.zipcode = zip
        }
      }

      EnvX.log('Update profilebody', profilebody)

      const remoteAccess = new RemoteAccess(this.$AuthService)
      remoteAccess.tokenAuthFunctionApp(
        '💈 ComponentRedeem.redeemInvitationCode', // log hint
        endpoints.updateprofileEndpoint, // endpoint
        environmentVariables.UPDATE_PROFILE_KEY, // code
        profilebody, // post body

        // hasPrerequisites ; must return 'true' to proceed
        () => {
          this.displayAnimatedProgressToast = true
          this.displayErrorToast = false
          this.savedFailureErrorMessage = ''
          return true
        },

        // onSuccess
        (data) => {
          this.formisdirty = false
          this.displayAnimatedProgressToast = false
          localStorage.removeItem('com.proinsight.county')
          localStorage.removeItem('com.proinsight.profession')
          localStorage.removeItem('com.proinsight.redeemid')
          localStorage.removeItem('com.proinsight.state')
          localStorage.removeItem('com.proinsight.zip')
          this.$emit('callRefreshProfileAndProperties', data.profile)
        },

        // All sort of errors
        (error, errorDescription) => {
          this.displayAnimatedProgressToast = false

          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            this.showLoggedOutBanner()
          } else {
            if (userInput) {
              this.savedFailureErrorMessage = errorDescription == null ? 'Invitation does not appear to be valid; has it been already redeemed? Can you request another code?' : errorDescription
              this.displayErrorToast = true
            } else {
              this.redeemInvitationCode = null
              this.displayRedeemDialog = true
            }
          }
        }
      )
    },

    showLoggedOutBanner() {
      // MustLoginSection should already have a modal
    }
  },

  computed: {
    redeemDialogModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    nullTimeoutProp() {
      return AlertTimeout.null
    },

    longTimeoutProp() {
      return AlertTimeout.long
    },

    infinityTimeoutProp() {
      return AlertTimeout.infinity
    }
  }
}
</script>

<style>
.centered {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
</style>

<template>
  <v-form ref="form" @submit.prevent>
    <v-row class="d-flex justify-center">
      <v-dialog v-if="displayRedeemDialog" v-model="redeemDialogModel" persistent>
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Redeem your invitation</v-toolbar-title>
            <v-spacer> </v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <div v-on:input="formDirty()">
                <br />
                <v-row justify="center" align="center">
                  <v-col cols="12" md="12">
                    <v-text-field v-model="redeemInvitationCode" text-h5 outlined label="Paste your invitation code" required :rules="rules.redeem"></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-btn :disabled="!formisdirty" color="primary" variant="text" @click="redeemInvitationCodeAction(true)"> Redeem invitation </v-btn>
                </v-row>
                <br /><br />

                <v-card-subtitle style="display: flex; justify-content: flex-start">*The code is in your ProInsight invitation email </v-card-subtitle>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" align="center" class="footer">
      <v-col cols="12" sm="12">
        <v-snackbar loading v-model="displayAnimatedProgressToast" :timeout="longTimeoutProp">
          Redemption…
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-snackbar>
      </v-col>
    </v-row>
    <v-snackbar v-model="displayErrorToast" :timeout="infinityTimeoutProp" color="error">
      <v-container fluid ma-0 pa-0 ml-4 fill-width style="width: 100%">
        <span class="centered">
          <span>{{ savedFailureErrorMessage }}</span>
          <v-spacer />
          <v-btn
            @click="
              displayErrorToast = false
              redeemInvitationCode = ''
            "
            text
            style="display: flex; justify-content: flex-end"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </span>
      </v-container>
    </v-snackbar>
  </v-form>
</template>
